
//App.js
import React, { lazy, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { AuthContext } from './context/UserContext'


const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function PrivateRoute({ children, ...rest }) {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() => (user != null ? children : <Redirect to="/login" />)}
    />
  );
}

function App() {
  // const { user } = useContext(AuthContext);

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute path="/app" >
            <Layout />
          </PrivateRoute>
          {/* Place new routes over this */}
          {/* <Route path="/app" component={Layout} /> */}
          {/* If you have an index page, you can remove this Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
