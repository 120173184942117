//index.jss
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import {GoogleOAuthProvider} from '@react-oauth/google'
import { AuthProvider } from './context/UserContext'

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <GoogleOAuthProvider clientId="207916354682-v1d0dlu9grvbdgat5lsiubnv1mup9j98.apps.googleusercontent.com">
  <SidebarProvider>
    <AuthProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences>
        <App />
      </Windmill>
    </Suspense>
  </AuthProvider>
  </SidebarProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

// "cz": "git-cz",
// "release": "release-it",
// "test": "react-scripts test",
// "eject": "react-scripts eject"