import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

// Create AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState({
    licenses: { all: [], active: [], expiring: [] },
    transactions: [],
  });
  const [error, setError] = useState(null);
  const BASE_URL = 'https://fx-bot-license.onrender.com'
  // const BASE_URL = 'http://localhost:3001'


  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
        if (codeResponse){
            // setUser(codeResponse)
            // console.log(codeResponse)
            axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                  Authorization: `Bearer ${codeResponse.access_token}`,
                  Accept: 'application/json',
                },
              })
              .then((res) => {
                setProfile(res.data);
                setUser(res.data);
                fetchLicenses();
                 fetchTransactions();
                Cookies.set('user', JSON.stringify(res.data)); // Save user info to cookies
              })
              .catch((err) => console.log(err));
          }
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const logout = () => {
    googleLogout();
    setUser(null);
    setProfile(null);
    Cookies.remove('user'); // Clear user info from cookies
  };
  
    const fetchLicenses = async (user) => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user/${user}/getdata`);
        // console.log(response.data)
        setData(prevData => ({
          ...prevData,
          licenses: {
            all: response.data.data.reverse(),
            active: response.data.active.reverse(),
            expiring: response.data.ex.reverse(),
          },
        }));
      } catch (err) {
        setError(err);
      }
    };

    const fetchTransactions = async (user) => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user/${user}/transactions`);
        
        setData(prevData => ({
          ...prevData,
          transactions: response.data.transactions.reverse(),
        }));
      } catch (err) {
        setError(err);
        // setError(err.response.data.error);
      }
    };
  
    // useEffect(() => {
    //   fetchLicenses();
    //   fetchTransactions();
    // }, [user]);

  useEffect(() => {
    // Load user from cookies when component is mounted
    const savedUser = Cookies.get('user');
    if (savedUser) {
      const latest = JSON.parse(savedUser)
      setUser(latest);
      fetchLicenses(latest.id);
      fetchTransactions(latest.id);
    }
  }, []);


  //Modal dialog
  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  

//   useEffect(() => {
//     if (user) {
//       axios
//         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//           headers: {
//             Authorization: `Bearer ${user.access_token}`,
//             Accept: 'application/json',
//           },
//         })
//         .then((res) => {
//           setProfile(res.data);
//         })
//         .catch((err) => console.log(err));
//     }
//   }, [user]);

// console.log(data)

  return (
    <AuthContext.Provider value={{ data, user, profile, login, logout, isAuthenticated:Boolean(user), closeModal, openModal, isModalOpen, setIsModalOpen, BASE_URL  }}>
      {children}
    </AuthContext.Provider>
  );
};
